import styled from "styled-components";
import { Link } from "react-router-dom";
import devices from "../../style/devices";
import { SubTitle, Paragraph } from "../../style/text";

export const ThreeColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.m2};

  @media ${devices.desktop} {
    margin-bottom: ${({ theme }) => theme.spacing.m3};
  }
`;

export const Stage = styled(SubTitle)`
  color: ${({ theme, selected, color }) =>
    selected ? color : theme.colors.grey[500]};
`;

export const Underline = styled.div`
  border-bottom: 0.2rem solid;
  border-color: ${({ theme, selected, color }) =>
    selected ? color : theme.colors.grey[300]};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${devices.tablet} {
    max-width: 35rem;
  }
`;

export const Description = styled(Paragraph)`
  padding-bottom: ${({ theme }) => theme.spacing.s2};
`;

export const Typeform = styled.div`
  padding: ${({ theme }) => `${theme.spacing.m1}`};
  width: 500px;

  @media ${devices.tablet} {
    width: 500px;
  }
`;

export const Input = styled.input`
  font-family: ${({ theme }) => theme.font.family.lato};
  background-color: ${({ theme }) => theme.colors.grey[200]};
  border: 0;
  border: solid 0.1rem;
  border-radius: 0.5rem;
  border-color: transparent;
  padding: ${({ theme }) => theme.spacing.xs3};
  margin-bottom: ${({ theme }) => theme.spacing.xs3};
  font-size: ${({ theme }) => theme.font.size[3]};

  &:focus {
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.grey[300]};
    outline: 0;
  }
`;

export const LinkText = styled(Link)`
  color: ${({ theme }) => theme.colors.secondary.normal};
  text-decoration: none;
  text-decoration-color: ${({ theme }) => theme.colors.secondary.normal};
`;

export const LinkTextTertiary = styled(Link)`
  color: ${({ theme }) => theme.colors.tertiary.normal};
  text-decoration: none;
  text-decoration-color: ${({ theme }) => theme.colors.tertiary.normal};
`;

export const IconWrapper = styled.div`
  padding-right: ${({ theme }) => theme.spacing.xs2};
`;

export const Reason = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: ${({ theme }) => theme.spacing.xs2};

  &:last-of-type {
    padding-bottom: ${({ theme }) => theme.spacing.s2};
  }

  @media ${devices.tablet} {
    align-items: center;
  }
`;

export const ErrorMessage = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.tertiary.normal};
  padding-top: ${({ theme }) => theme.spacing.s2};
`;

export const StoreImg = styled.img`
  height: ${({ theme }) => "4rem"};
`;

export const StoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  padding-bottom: 2rem;
  grid-column: 1 / span 2;
  justify-self: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 5rem;

  @media ${devices.tablet} {
    min-height: auto;
    flex-direction: row;
    width: 23rem;
    justify-content: space-between;
  }
  @media ${devices.desktop} {
    flex-direction: row;
    width: 25rem;
    justify-content: space-between;
  }
`;

export const CenteredDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
