import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { SubTitle, Paragraph } from "../../style/text";
import { Container, LongCopy, LinkText } from "./style";

const FourOFour = () => (
  <Fragment>
    <Helmet>
      <title>404 | Spill</title>
      <meta
        name="description"
        content="Spill lets you message your designated counsellor every day. Affordable talking therapy whenever and wherever you need it."
      />
      <meta
        name="keywords"
        content="Spill,Mental health,counselling,counsellor,chat,online,therapy,help"
      />
    </Helmet>
    <Container>
      <SubTitle>error 404</SubTitle>
      <LongCopy>
        Maybe the page you’re looking for started doing asynchronous
        message-based therapy, had several life-changing realisations, reached a
        deeper understanding of its true self and decided it didn’t really want
        to be a page anymore: it wanted to be a BOOK!
      </LongCopy>
      <LongCopy>...or maybe it’s just lost.</LongCopy>
      <Paragraph>
        Whatever the case, best make your way back to the{" "}
        <LinkText to="/">homepage</LinkText>.
      </Paragraph>
    </Container>
  </Fragment>
);

export default FourOFour;
