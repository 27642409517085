import React, { Fragment } from "react";
import { render } from "react-dom";
import { ThemeProvider } from "styled-components";
import theme from "./style/theme";
import AppRouter from "./Router";
import Footer from "./components/footer/index";
import GlobalStyle from "./style/global";
import 'babel-polyfill';


const Application = () => (
  <ThemeProvider theme={theme}>
    <Fragment>
      <GlobalStyle />
      <AppRouter />
      <Footer />
    </Fragment>
  </ThemeProvider>
);

render(<Application />, document.getElementById("root"));
