import styled from "styled-components";
import { Link } from "react-router-dom";
import { Section } from "../../style/base-components";
import { SectionTitle } from "../../style/text";

export const Container = styled(Section)`
  /* min-height: 90vh; */
  display: flex;
  flex-direction: column;
`;

export const LongCopy = styled(SectionTitle)`
  line-height: ${({ theme }) => theme.lineHeight.small};
  max-width: 20rem;
`;

export const LinkText = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary.normal};
`;
