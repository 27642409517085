import styled from "styled-components";
import devices from "../../style/devices";

export const Container = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  user-select: none;
  z-index: 0;
  width: 100%;
  margin: 0 auto;

  @media ${devices.tablet} {
    width: 90%;
  }
  @media ${devices.desktop} {
    width: 90%;
    max-width: 1024px;
  }
`;

export const DiamondWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  user-select: none;
`;

export const Diamond = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  transform: rotate(45deg);
  display: inline-block;
  margin: 0.5rem;
  user-select: none;
`;

export const PinkDiamond = styled(Diamond)`
  background-color: ${({ theme }) => theme.colors.tertiary.normal};
`;

export const YellowDiamond = styled(Diamond)`
  background-color: ${({ theme }) => theme.colors.secondary.normal};
`;

export const TealDiamond = styled(Diamond)`
  background-color: ${({ theme }) => theme.colors.primary.normal};
`;

export const BlueDiamond = styled(Diamond)`
  background-color: ${({ theme }) => theme.colors.blue.normal};
`;

export const Line = styled.div`
  height: 0.08rem;
  background-color: ${({ theme }) => theme.colors.grey[400]};
  margin: 1rem;
  user-select: none;
  flex: 1;
`;
